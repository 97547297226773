import axios from 'axios'
import BaseData from '@/assets/config/BaseData.js';
import dataStorage from '@/../utils/dataStorage.js'
import { Message } from "element-ui"; //引入下载的插件
// 创建axios实例
const service = axios.create({
        baseURL: BaseData.baseUrl, // api的base_url
        timeout: 20000 // 请求超时时间
    })
    //http request 拦截器
service.interceptors.request.use( //在每次请求中使用拦截器
        config => {
            //判断cookie中是否有token
            if (dataStorage.getBaseData("iotechtj_token")) {
                //getBaseData中有tolen 那么把token放入header中
                config.headers['token'] = dataStorage.getBaseData("iotechtj_token")
            }



            return config
        },
        err => {
            return Promise.reject(err);
        }
    )
    // http response 拦截器
service.interceptors.response.use(
    response => {
        if (response.data.message == "身份认证失败") {
            console.log("身份认证失败")
                // 返回 错误代码-1 清除ticket信息并跳转到登录页面
                // window.location.href = "/Login"
            window.location.href = "/index.html"

            // this.$router.push('/login')
            return
        } else {
            if (response.data.code !== 20000) {
                //25000：订单支付中，不做任何提示
                if (response.data.code != 25000) {
                    Message({
                        message: response.data.message || 'error',
                        type: 'error',
                        duration: 5 * 1000
                    })
                }
            } else {
                return response;
            }
        }
    },
    error => {
        return Promise.reject(error.response) // 返回接口返回的错误信息
    });
export default service