//服务器IP
// const serverIP = "47.104.146.47"
// const serverIP = "39.98.115.252"
// const serverIP = "82.156.18.109" // 天津大学
// const serverIP = "8.142.126.156" // 中泰云创
// const serverIP = "47.96.95.140" //河马
// 城市大厦
const serverIP = "150.223.39.237"

// const serverIP = "localhost"
//服务器端口
const serverPort = "8081"
    //服务器域名
const baseUrl = "http://" + serverIP + ":" + serverPort + "/"

//crt图片上传接口
const crtPictureUploadUrl = baseUrl + "iotechserver/crtInfo/addCRTInfo"


export default {
    serverIP,
    serverPort,
    baseUrl,
    crtPictureUploadUrl
}