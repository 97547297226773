//写cookies
function setCookie(name,value)
{
    var Days = 3650;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days*24*60*60*1000);
    document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString()+"; path=/;";

}
//读取cookies
function getCookie(name)
{
    var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
    if(arr=document.cookie.match(reg)) return unescape(arr[2]);
    else return null;
}

export default {
    getBaseData(key) {
        if(!window.localStorage) {
            return  getCookie(key);
        }else{
            return window.localStorage.getItem(""+key);
        }
        
    },
 //存入 键值对的 方法
    setBaseData(key,value) {
        if(!window.localStorage){
            setCookie(key,value);
        }else{
            window.localStorage.setItem(""+key,value);
        }
        
    },





}